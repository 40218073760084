<template>
  <div class="map">

    <div v-show="showMap" id="map" @zoom="zoom"></div>

    <!-- Error and Message -->
    <div v-if="!showMap" class="retry">
      <div>

        <h2 v-if="error" class="bk">{{ error }}</h2>
        <Loading/>

      </div>
    </div>
  </div>

</template>

<script>
import L from 'leaflet'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Map',

  data() {
    return {
      loaded: false,

      //Map
      map: null,
      mapOptions: {
        attributionControl: false
      },

      //Map tiles
      tileUrl: process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      //Markers
      markers: {},
      svgs: {}
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Loading
  },

  computed: {
    ...mapGetters( 'Sockets', {
      connected: 'connected',
      initalised: 'initalised',
      established: 'established',
      socket: 'socket',
      error: 'error'
    }),

    ...mapGetters( 'Location', {
      latitude: 'latitude',
      longitude: 'longitude',
      city: 'city',
      population: 'population'
    }),

    ...mapGetters( 'User', {
      id: 'id',
      color: 'color',
      icon: 'icon'
    }),

    ...mapGetters( 'Map', {
      users: 'markers'
    }),

    position() {
      return [ this.city.latitude, this.city.longitude ]
    },

    showMap() {
      return this.connected && !this.error //TODO maybe initalised too
    }
  },

  methods: {
    ...mapActions( 'Sockets', {
      connect: 'connect',
      initalise: 'initalise'
    }),

    ...mapActions( 'Map', {
      listeners: 'listeners'
    }),

    marker( user ) {
      const color = user.color || this.color
      //TODO fix when comes to the map page
      const icon = this.$Utils.User.icon( user.icon || this.icon.file )
      return this.$Utils.Map.marker( color, icon ) 
    },

    joined( user ) {
      if ( !user || user.id === this.id ) return

      const marker = L.marker( [ user.latitude, user.longitude ], this.marker( user ) )
      this.markers[ user.id ] = marker.addTo( this.map )
    },

    left( data ) {
      //TODO do we need the lodash
      const user = this.$_.get( data, 'user' )
      if ( !user ) return

      this.map.removeLayer( this.markers[ user ])
      delete this.markers[ user ]
    },

    zoom() {
      return this.$Utils.Map.zoom( this.population )
    }
  },

  watch: {
    connected() {
      if ( this.loaded ) return
      this.loaded = true

      this.$nextTick(() => {
        this.map = L.map( 'map', this.mapOptions ).setView( this.position, this.zoom() )
        L.tileLayer( this.tileUrl ).addTo( this.map )

        // //Add everyone on the map
        // this.users.forEach( user => this.joined( user ) )

        //Add yourself using real co-ordinates
        // const marker = L.marker( [ this.latitude, this.longitude ], this.marker() )
        // marker.addTo( this.map )

        // //When a user joins, add their marker
        // this.socket.on( 'joined', data => this.joined( data.user ) )

        // //When a user leaves the room, remove their marker
        // this.socket.on( 'left', this.left )
      })
    }
  },

  mounted() {
    if ( !this.established ) this.connect()

    //Setup vuex store listeners
    //this.listeners()
  },

  beforeDestroy() {
    //TODO test
    if ( this.map ) {
      this.map.off()
      this.map.remove()
    }

    if ( this.socket ) {
      // this.socket.off( 'joined', this.joined )
      // this.socket.off( 'left', this.left )
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/_index.scss";

.map {
  position: relative;

  #map {
    margin: 0 auto;
    @include full-stretch;
  }
}
</style>
